export const hotServices: string[] = [
  'pdf_to_markdown',
  'watermark-remove',
  'recognize_table_multipage',
  'manipulation_detection',
  'pdf-to-word',
  'doc_restore',
  'dewarp',
  'id_card',
  'bank_card',
  'vehicle_license',
  'driver_license',
  'household_register',
  'business_card',
  'passport',
];
export const newServices: string[] = [
  'open_kie_vlm_engine',
  'acge_text_embedding',
  'bill_recognize_v2',
  'face_forgery_detection',
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hiddenServices: string[] = [
  'doc_extraction', // 合同抽取
  'doc_compare', // 合同比对
  'document', // 办公文档识别
  'contents-extract', // 通用NLP信息抽取
  'recognize-document-3d1-multipage', // 通用文字识别多页PDF版
  'verify_vat', // 发票验真
  'blockchain_validate', // 区块链发票验真
];

export const isOpenKie = (item: any) => {
  return [17].includes(item.interaction) || ['open_kie_vlm_engine'].includes(item.service);
};

export const filetHiddenList = (list: any[]) => {
  if (!Array.isArray(list)) return [];
  // 隐藏无体验中心的服务，智能文档抽取例外
  return list.filter(
    (item) => (item.exper_center_status === 1 || isOpenKie(item)) && item.interaction !== -1,
  );
};
