import Link from '@/components/Link';
import { Row, Col, Popover } from 'antd';
import type { MouseEvent } from 'react';
import { useRef } from 'react';
import React, { useEffect, useState } from 'react';
import { menu as menuList, popupMenu } from './constants';
import styles from './index.module.scss';
import Collapse from '@/components/common/Collapse';
import classNames from 'classnames';
import { useDebounceFn, useDocumentVisibility, useThrottleFn } from 'ahooks';
import Image from 'next/image';
import { headerContainer } from '../../store';
import { userModelContainer } from '@/store/userModel';
import { track } from '@/utils/track';

const NaveBar = () => {
  const { popupVisible, setPopupVisible } = headerContainer.useContainer();
  const { markList } = userModelContainer.useContainer();

  const [activeKey, setActiveKey] = useState('');

  const targetRef = useRef<HTMLElement>();

  const mouseEnterRef = useRef(false);

  const visibilityState = useDocumentVisibility();

  const wait = 150;

  const { run: changeMouseHandle } = useDebounceFn(
    (e, isEnter) => {
      mouseEnterRef.current = isEnter;
      if (isEnter) {
        changePopup(e);
      } else {
        targetRef.current = undefined;
        if (isLeavePop(e)) {
          onClose();
        }
      }
    },
    { wait },
  );

  const { run: changePopupHandle } = useThrottleFn(
    (e) => {
      if (mouseEnterRef.current) {
        changePopup(e);
      }
    },
    { wait, leading: false },
  );

  useEffect(() => {
    if (visibilityState === 'hidden') {
      onClose();
    }
  }, [visibilityState]);

  const onClose = () => {
    setActiveKey('');
    setPopupVisible(false);
  };

  const changePopup = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (targetRef.current === target) return;
    targetRef.current = target;
    const dataKey = target.getAttribute('data-key');
    const popContent = document.querySelector('#pop-content');
    if (target && dataKey) {
      const item = menuList.find((menu) => menu.key === dataKey);
      if (item) {
        track({ name: '内容曝光', keyword: `导航栏【${item.title}】` });
      }
      setActiveKey(dataKey);
      setPopupVisible(true);
      return;
    }
    if (popContent?.contains(target)) {
      setPopupVisible(true);
      return;
    }
    onClose();
  };

  const isLeavePop = (e: any) => {
    const { clientX, clientY } = e;
    const popContent: HTMLElement | null = document.querySelector('#pop-content');
    if (popContent) {
      const { offsetHeight, offsetWidth } = popContent;
      const { offsetHeight: bodyHeight } = document.body;
      const maxHeight = bodyHeight > offsetHeight ? offsetHeight : bodyHeight;
      if (clientY > 90 && clientY < maxHeight - 30 && clientX > 30 && clientX < offsetWidth - 30) {
        return false;
      }
    }
    return true;
  };
  const content = (
    <div className={styles.adtip}>
      <p>
        智能文字产品套餐上线，全场<span>5折</span>起
      </p>
    </div>
  );
  const jumpBlank = (menuItem) => {
    trackNav(menuItem);
    window.open(menuItem.path, menuItem.target);
  };
  const trackNav = (menuItem) => {
    track({ name: '按钮点击', keyword: `导航栏【${menuItem.title}】` });
  };
  return (
    <div
      onMouseLeave={(e: any) => {
        changeMouseHandle(e, false);
      }}
      onMouseMove={changePopupHandle}
      onMouseEnter={(e) => {
        changeMouseHandle(e, true);
      }}
    >
      <Row gutter={[30, 0]}>
        {menuList.map((menuItem) =>
          menuItem.showCord ? (
            <Col
              className={classNames(styles['popover-container'], styles.link, {
                [styles['nav-hover']]: menuItem.key === activeKey,
              })}
              data-key={menuItem.key}
              key={menuItem.key}
              onClick={() => jumpBlank(menuItem)}
            >
              {menuItem.title}
            </Col>
          ) : (
            <Col key={menuItem.key} className={classNames(styles.link)}>
              {menuItem.newTag ? (
                <Popover
                  placement="bottom"
                  content={content}
                  trigger="hover"
                  overlayClassName={styles.textin_admask}
                >
                  <Link
                    href={menuItem.path}
                    passHref
                    isNative={!!menuItem.target}
                    target={menuItem.target === '_self' ? '_self' : '_blank'}
                    onClick={() => {
                      trackNav(menuItem);
                    }}
                  >
                    <div className={styles.title}>{menuItem.title}</div>
                    <span className={styles.newTip}>NEW</span>
                  </Link>
                </Popover>
              ) : (
                <Link
                  href={menuItem.path}
                  passHref
                  isNative={!!menuItem.target}
                  target={menuItem.target === '_self' ? '_self' : '_blank'}
                  onClick={() => {
                    trackNav(menuItem);
                    menuItem.onClick?.();
                  }}
                >
                  <div
                    {...(menuItem.attrs || {})}
                    className={styles.title}
                    style={{ width: menuItem.width }}
                  >
                    {menuItem.title}
                  </div>
                </Link>
              )}
            </Col>
          ),
        )}
      </Row>
      <Collapse
        collapse={popupVisible}
        afterLeave={() => {
          setActiveKey('');
        }}
      >
        <div
          id="pop-content"
          data-key={activeKey}
          className={styles['popup-wrapper']}
          style={{ top: markList ? '117px' : '57px' }}
        >
          {popupMenu.map((item) => {
            const Content: any = item.component;
            return (
              <div
                className={classNames(styles['popover-content'], {
                  [styles['popover-active']]: item.key === activeKey,
                })}
                key={item.key}
              >
                <Content active={item.key === activeKey} />
                <div className={styles['popover-close']}>
                  <Image
                    width={20}
                    height={20}
                    src="/icons/ic-close.svg"
                    alt="close"
                    onClick={onClose}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};
export default NaveBar;
