import classNames from 'classnames';
import Image from 'next/image';
import styles from './index.module.less';
import { GlobalStoreContainer } from '@/store';

export default function H5Header() {
  const { isH5 } = GlobalStoreContainer.useContainer();
  return (
    isH5 && (
      <div className={classNames(['global-h5-header', styles['h5-product-intro-wrapper']])}>
        <Image src="/images/user/login_logo@2x.png" width="118" height="30" alt="" />
        <span className={styles['product-title']}>智能文字识别产品</span>
      </div>
    )
  );
}
